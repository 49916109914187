/**
 * ID: bh-global-css
 * Name: global.scss
 * Description: App-specific global themes. Applied after base and canned stylesheets applied.
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Adjust cta-button style
 * 2021-07-27 - MW - v3: Added missing alert danger class
 * 2022-05-25 - MW - v4: Split global.scss into base.all.scss, base.desktop.scss, base.mobile.scss, canned.scss
 */

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* BH-Starter CSS utils */
@import "theme/canned.scss";
@import "theme/base.all.scss";
@import "theme/base.mobile.scss";
@import "theme/base.desktop.scss";

@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

/* Import Quill CSS */
@import "~quill/dist/quill.snow.css";

/* Fix for uncentered date field */
.mat-mdc-form-field-flex {
  align-items: center !important;
}

.mat-calendar-body-cell-content {
  border-style: none !important;
}

.mat-calendar-body-cell-content.mat-focus-indicator.mat-calendar-body-selected {
  color: #fff;
}

.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  // padding-right: 0;
  padding-top: 16px;
  background: var(--ion-color-step-50);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label {
  padding-bottom: 8px;
}

.mat-datepicker-content .mat-calendar {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 8px;
  height: auto !important;
}

.form-group-box {
  margin: 8px 8px 24px 8px;
  padding: 16px;
  border: 1px solid var(--font-fade-color);
  border-radius: 16px;

  h3 {
    margin-top: 0;
  }
}

.editor-tools {
  padding: 16px;
  text-align: center;

  ion-button {
    min-width: 200px;
  }
}

.record-row {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 12px 0;
  max-width: 100%;

  .record-label {
    min-width: 120px;
    margin-bottom: 8px;
    display: flex;

    ion-icon {
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .record-value {
    font-weight: bold;
    line-break: anywhere;
    display: flex;
    flex-wrap: wrap;
  }
}

.editor-segment {
  margin: 32px auto;
  max-width: 500px;
  background: #9a169c;
  color: #fff;

  ion-segment-button.segment-button-checked {
    color: #9a169c;
  }
}

.category-container {
  // display: flex;
  // min-height: 40vh;
  position: relative;
  margin: 8px;
  padding: 8px;

  &.edit-mode {
    border: 2px solid transparent;
    border-radius: 8px;

    &:hover {
      border-color: var(--app-gradient-color-1);

      .editor-toolbar {
        position: absolute;
        opacity: 1;
        right: 8px;
        pointer-events: all;
      }
    }
  }

  .editor-toolbar {
    display: flex;
    background: var(--app-gradient-color-1);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    position: absolute;
    right: 24px;
    top: -28px;
    opacity: 0;
    pointer-events: none;
    transition: all 0.2s;

    a {
      color: #fff;
      font-size: 13px;
      padding: 4px;
      text-decoration: none;
      display: flex;
      align-items: center;
      border-radius: 2px;

      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }

      ion-icon {
        color: #fff;
        font-size: 20px;
        margin-right: 4px;
      }
    }

    // ion-color {
    //   color: #fff;
    //   font-size: 20px;
    // }
  }
}

.cc-heading {
  font-size: 24px;
  font-weight: 600;
  display: flex;
  align-items: center;

  .cc-heading-thumbnail {
    height: 48px;
    width: 48px;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-right: 16px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: none;
  }

  .cc-heading-text {
    flex: 1 1 auto;
    border-bottom: 1px solid var(--app-border-color);
    padding: 8px 0;
  }
}

.cc-listing {
  flex: 1 1 65%;
  padding: 48px 0 0 0;
  margin-left: 16px;
  display: flex;
  flex-wrap: wrap;
  position: static;
}

@media (max-width: 767px) {
  .record-row {
    flex-direction: column;
  }

  .cc-listing {
    margin-left: 0;
  }

  .category-container {
    padding: 0;
    margin: 0;
  }
}

.content-block-inner,
.ai-information,
.te-editor-content {
  &.ql-editor {
    padding: 12px 0;
  }

  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    padding-top: 0;
  }

  h4 {
    margin-top: 16px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  p {
    margin: 8px 0 16px 0;
  }

  iframe {
    height: 35vh;
    width: 100%;
  }

  ul,
  ol {
    margin-top: 16px;

    li {
      margin-bottom: 16px;
    }
  }
}

.dark {
  .ql-editor {
    p,
    span,
    em,
    u {
      color: var(--font-color) !important;
    }

    a,
    a u {
      color: var(--ion-color-primary) !important;
    }
  }
}

.tags-listing {
  display: flex;
}

.tag {
  display: inline-flex;
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  padding: 8px 12px;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  color: var(--font-color);
  text-decoration: none;
  font-weight: normal;
  text-transform: capitalize;
  max-height: 36px;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    background: rgba(var(--ion-color-primary-rgb), 0.2);
  }

  ion-icon {
    margin-right: 8px;
    font-size: 20px;
    color: var(--ion-color-primary);

    &.action-icon {
      margin-right: 0;
    }

    &.end-icon {
      margin-left: 8px;
    }
  }

  a {
    display: inline-flex;
  }
}

quill-editor {
  display: block;
  width: 100%;

  .ql-toolbar.ql-snow {
    border: 1px solid transparent;
    border-top: 1px solid var(--app-border-color);
    padding: 12px 0;
    text-align: left;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor,
  .ql-snow .ql-editor {
    background: var(--background-color);
    border: 1px solid var(--app-border-color);
    border-radius: 8px;
    min-height: 200px;
    font-size: 16px;
  }

  .ql-snow .ql-tooltip.ql-editing input[type="text"] {
    color: var(--font-color);
    background: var(--background-color);
  }

  .ql-snow .ql-tooltip {
    z-index: 100;
  }
}

.te-editor-content {
  border: 1px solid #ccc;
  background: var(--background-color);
  border-radius: 8px;
  min-height: 200px;
  padding: 8px;

  &:focus {
    outline: 1px solid var(--ion-color-primary);
  }

  &.script {
    font-family: 'Courier New', Courier, monospace;
    display: block;
    width: 100%;
    font-weight: 600;
    padding: 12px;
    // overflow: auto;
    // white-space: nowrap;
  }
}


.source-toggle {
  display: flex;
  align-items: center;
  padding: 16px 8px;

  ion-toggle {
    margin-right: 16px;
  }
}

.event-thumbnail {
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  border-radius: 100px;
  min-height: 48px;
  max-height: 48px;
  min-width: 48px;
  max-width: 48px;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  margin-left: 4px;

  span {
    font-size: 13px;
    font-weight: normal;
    display: block;
  }
}

.app-thumbnail {
  background: rgba(var(--ion-color-primary-rgb), 0.1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 16px;
  margin-left: 4px;
}

youtube-player {
  iframe {
    height: 100%;
    width: 100%;
    display: block;
  }
}

.ai-information {
  h2,
  h3 {
    margin-top: 0 !important;
  }
}

body.dark {
  .editor-segment {
    ion-segment-button.segment-button-checked {
      --indicator-color: var(--background-shading-color) !important;
      color: #fff !important;
    }
  }

  .ion-segment-tabs {
    ion-segment-button {
      border-left: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
      border-right: 1px solid rgba(255, 255, 255, 0.2) !important;
    }
  }
}

ion-list.list-md {
  background: transparent;
}

.cc-heading-text {
  font-size: 24px;
  font-weight: 600;
  flex: 1 1 auto;
  border-bottom: 1px solid var(--app-border-color);
  padding: 8px 0;
}
